import React from "react"

export default function Error404() {
  return (
    <section class="mainContent">
      <h1>Error 404<br />Not Found</h1>
      <p>Please return to the homepage: <a href="/">vinnyfarms.com</a>.</p>
    </section>
  );
}
